export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function waitForEvent<T extends Event>(target: EventTarget, event: string): Promise<T> {
  return new Promise((resolve) => {
    const handler: EventListener = (evt: Event) => {
      const e = evt as T;
      target.removeEventListener(event, handler);
      resolve(e);
    };
    target.addEventListener(event, handler);
  });
}

export function waitUntil(predicate: () => boolean): Promise<void> {
  return new Promise((resolve) => {
    function checkPredicate() {
      if (predicate()) {
        resolve();
      } else {
        requestAnimationFrame(checkPredicate);
      }
    }
    checkPredicate();
  });
}

export function waitWhile(predicate: () => boolean): Promise<void> {
  return new Promise<void>((resolve) => {
    const checkPredicate = () => {
      if (predicate()) {
        requestAnimationFrame(checkPredicate);
      } else {
        resolve();
      }
    };
    checkPredicate();
  });
}

export function wait(milliseconds: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

export function waitForSeconds(seconds: number): Promise<void> {
  return wait(seconds * 1000);
}

export function waitForAnimationFrame(): Promise<number> {
  return new Promise((resolve) => {
    requestAnimationFrame((timestamp) => {
      resolve(timestamp);
    });
  });
}
